/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var minheight = $('.intro').height();
        $('header').css("min-height", minheight + 80);
        $('.header-image').css("min-height", minheight + 80);

        set_header_height();

        function set_header_height(){
          if ($(window).width() < 980) {
            $('.header-image').css("min-height", "70vh");
          }
        }

        $( window ).resize(function() {
          set_header_height();
        });

        //if browser width is small, then scrap min-height and set height to auto or 70vh

        $(function() {
          $('body').on('click', '.navigation a', function(event) {
              var $anchor = jQuery(this);
              $('html, body').stop().animate({
                  scrollTop: jQuery($anchor.attr('href')).offset().top
              }, 1000, 'easeInOutExpo');
              event.preventDefault();
          });
        });

        //sly.js
        var $frame = $('#cycleitems');
        var $wrap  = $frame.parent();

        // Call Sly on frame
        $frame.sly({
          horizontal: 1,
          itemNav: 'basic',
          smart: 1,
          activateOn: 'click',
          mouseDragging: 1,
          touchDragging: 1,
          releaseSwing: 1,
          startAt: 0,
          scrollBar: $wrap.find('.scrollbar'),
          scrollBy: 1,
          speed: 300,
          elasticBounds: 1,
          dragHandle: 1,
          dynamicHandle: 1,
          clickBar: 1,

          // Cycling
          cycleBy: 'items',
          cycleInterval: 1000,
          pauseOnHover: 1,

          // Buttons
          prev: $wrap.find('.prev'),
          next: $wrap.find('.next')
        });

        // Pause button
        $wrap.find('.pause').on('click', function () {
          $frame.sly('pause');
        });

        // Resume button
        $wrap.find('.resume').on('click', function () {
          $frame.sly('resume');
        });

        // Toggle button
        $wrap.find('.toggle').on('click', function () {
          $frame.sly('toggle');
        });



        var swiper = new Swiper('.swiper-container', {
          slidesPerView: 3,
          spaceBetween: 30,
          loop: true,
          autoplay: {
            delay: 10000,
            disableOnInteraction: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
            1400: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 5,
            }
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Music
    'music': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.lyrics-holder').scroll(function(){
          $(this).removeClass("foo");
        })
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
